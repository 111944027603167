<template>
	<errorContainer :error="erreur" :warning="warning">
		<div v-if="$screen.width <= 576" class="my-expense-mobile">
			<div class="title">
				<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
					{{ FormMSG(1, 'My purchase orders') }}
				</h1>
			</div>
			<div class="my-expense-actions" :class="{ 'pt-16': $isPwa() }">
				<div class="d-flex justify-content-center wapper">
					<div class="btn-action text-center" @click="openCreatePoModal">
						<img src="svgs/po.svg" alt="Purshase order" />
						<span class="px-2">{{ FormMSG(5, 'Add Purchase Order') }}</span>
					</div>
				</div>
			</div>
		</div>
		<div>
			<b-row align-h="between">
				<b-col md="6">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
						{{ FormMSG(1, 'My purchase orders') }}
					</h1>
				</b-col>
				<b-col v-if="$screen.width >= 576" md="6" class="text-right">
					<b-button variant="primary" @click="openCreatePoModal">
						{{ FormMSG(2, 'Create PO') }}
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<div class="d-flex flex-column w-100">
					<b-card no-body :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
						<b-tabs v-model="activeListTab">
							<b-tab v-if="$screen.width >= 576 && canSeeApprove" :title="FormMSG(500, 'Approve')">
								<div class="pr-1 pl-1">
									<ValidatePOs />
								</div>
							</b-tab>
							<b-tab :title="FormMSG(15, 'Drafts')" @click="getPoList(0)">
								<b-card-text class="py-0">
									<b-overlay :show.sync="isBusyDataTable" rounded="lg">
										<div v-if="notTransmittedAndRefusedPurchaseOrders.length === 0" class="empty-state">
											{{ FormMSG(33, 'No refused or in waiting purchases orders to send') }}
										</div>
										<div v-else>
											<b-row v-if="$screen.width >= 576" align-h="between" :class="`${$screen.width >= 992 ? 'mb-1' : ''}`">
												<b-col md="12" class="text-right pb-2">
													<b-button
														variant="primary"
														v-html="FormMSG(776767, 'Send po for validation')"
														style="margin-left: 20px"
														@click="handSendAllPoValidation"
													/>
												</b-col>
											</b-row>
											<div v-if="$screen.width <= 576" class="container-mobile">
												<div class="my-expense-mobile">
													<b-overlay :show.sync="isBusyDataTable" rounded="lg">
														<div class="content pb-3" v-if="notTransmittedAndRefusedPurchaseOrders.length > 0">
															<card-list-builder
																:use-new-version="true"
																:items="notTransmittedAndRefusedPurchaseOrders"
																:fields="mobileFields"
																:has-badge="true"
																:is-badge-use-props="true"
																:toggle-mode="true"
																badge-class="statusClass"
																badge-value="validatedStatus"
															>
																<template slot="actions" slot-scope="{ item }">
																	<!-- update po -->
																	<edit
																		class="action cursor-pointer"
																		:size="18"
																		@click="editPoMobile(item)"
																		v-if="notSubmittedState"
																	/>
																	<!-- send single po to validation -->
																	<send
																		class="action cursor-pointer"
																		:size="18"
																		@click="handleSendPOtoValidation(item.id)"
																		v-if="notSubmittedState && item.validated !== 4 && item.validated !== 16"
																	/>
																	<!-- delete po -->
																	<trash
																		class="action cursor-pointer"
																		:size="18"
																		@click="handleDeletePO(item)"
																		color="#EA4E2C"
																		v-if="notSubmittedState"
																	/>
																	<!-- detail po -->
																	<eye
																		class="action cursor-pointer"
																		:size="18"
																		@click="editPoMobile(item)"
																		v-if="submittedState"
																	/>
																</template>
															</card-list-builder>
														</div>
														<div v-else class="empty-data">
															<span v-if="notSubmittedState">{{
																FormMSG(33, 'No refused or in waiting purchases orders to send')
															}}</span>
															<span v-else-if="submittedState">{{ FormMSG(4, 'No purchase orders sent') }}</span>
														</div>
													</b-overlay>
													<b-row class="footer-fixed" v-if="notTransmittedAndRefusedPurchaseOrders.length > 0">
														<b-col cols="12">
															<b-button block size="lg" variant="primary" class="mb-2" @click="handSendAllPoValidation">
																<span>{{ FormMSG(776767, 'Send po for validation') }}</span>
															</b-button>
														</b-col>
													</b-row>
												</div>
											</div>
											<b-table
												v-else
												ref="unsentOrRefusedPurchaseOrderTable"
												:hover="hover"
												selectable
												:selectedVariant="selectedColor"
												:select-mode="selectMode"
												sticky-header="800px"
												:per-page="currentTablePagePerPage"
												:items="notTransmittedAndRefusedPurchaseOrders"
												:fields="unsentOrRefusedPurchaseOrderFields"
												:head-variant="hv"
												bordered
												small
												@row-clicked="handleRowClicked"
											>
												<template #cell(sendtoapp)="data">
													<b-button
														v-if="data.item.validated !== 4 && data.item.validated !== 16"
														size="sm"
														class="btn bg-transparent border-0"
														@click="handleSendPOtoValidation(data.item.id)"
													>
														<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="20" />
													</b-button>
												</template>
												<template #cell(rem)="data">
													<b-button size="sm" class="btn bg-transparent border-0" @click="handleDeletePO(data.item)">
														<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
													</b-button>
												</template>
												<template #cell(validated)="data">
													<div class="wrap-status">
														<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
															{{ data.item.validatedStatus }}
														</div>
													</div>
												</template>
											</b-table>
										</div>
									</b-overlay>
								</b-card-text>
							</b-tab>
							<b-tab :title="FormMSG(16, 'Pending')" @click="getPoList(1)">
								<div v-if="$screen.width <= 576" class="container-mobile pr-1 pl-1">
									<div class="my-expense-mobile">
										<b-overlay :show.sync="isBusyDataTable" rounded="lg">
											<div class="content pb-3" v-if="submittedValidatedHoDPurchaseOrders.length > 0">
												<card-list-builder
													:use-new-version="true"
													:items="submittedValidatedHoDPurchaseOrders"
													:fields="mobileFields"
													:has-badge="true"
													:is-badge-use-props="true"
													:toggle-mode="true"
													badge-class="statusClass"
													badge-value="validatedStatus"
												>
													<template slot="actions" slot-scope="{ item }">
														<!-- update po -->
														<edit class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="notSubmittedState" />
														<!-- send single po to validation -->
														<send
															class="action cursor-pointer"
															:size="18"
															@click="handleSendPOtoValidation(item.id)"
															v-if="notSubmittedState && item.validated !== 4 && item.validated !== 16"
														/>
														<!-- delete po -->
														<trash
															class="action cursor-pointer"
															:size="18"
															@click="handleDeletePO(item)"
															color="#EA4E2C"
															v-if="notSubmittedState"
														/>
														<!-- detail po -->
														<eye class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="submittedState" />
													</template>
												</card-list-builder>
											</div>
											<div v-else class="empty-data">
												<span v-if="notSubmittedState">{{ FormMSG(33, 'No refused or in waiting purchases orders to send') }}</span>
												<span v-else-if="submittedState">{{ FormMSG(4, 'No purchase orders sent') }}</span>
											</div>
										</b-overlay>
										<!-- <b-row class="footer-fixed" v-if="submittedPurchaseOrders.length > 0">
											<b-col cols="12">
												<b-button block size="lg" variant="primary" class="mb-2" @click="handSendAllPoValidation">
													<span>{{ FormMSG(776767, 'Send po for validation') }}</span>
												</b-button>
											</b-col>
										</b-row> -->
									</div>
								</div>
								<b-overlay v-else :show.sync="isBusyDataTable" rounded="lg">
									<div v-if="submittedValidatedHoDPurchaseOrders.length === 0" class="empty-state">
										{{ FormMSG(4, 'No purchase orders sent') }}
									</div>
									<div v-else>
										<b-table
											ref="submittedPurchaseOrderTable"
											:hover="hover"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											bordered
											small
											:filter="filter"
											sticky-header="700px"
											:head-variant="hv"
											:per-page="currentTablePagePerPage"
											responsive="sm"
											:current-page="currentTablePageSubmitedPO"
											:items="submittedValidatedHoDPurchaseOrders"
											:fields="submitedPurchaseOrdersFields"
											@row-clicked="handleRowClicked"
										>
											<template #cell(validated)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
										</b-table>
									</div>
								</b-overlay>
							</b-tab>
							<b-tab :title="FormMSG(17, 'Approved')" @click="getPoList(2)">
								<div v-if="$screen.width <= 576" class="container-mobile pr-1 pl-1">
									<div class="my-expense-mobile">
										<b-overlay :show.sync="isBusyDataTable" rounded="lg">
											<div class="content pb-3" v-if="validatedPurchaseOrders.length > 0">
												<card-list-builder
													:use-new-version="true"
													:items="validatedPurchaseOrders"
													:fields="mobileFields"
													:has-badge="true"
													:is-badge-use-props="true"
													:toggle-mode="true"
													badge-class="statusClass"
													badge-value="validatedStatus"
												>
													<template slot="actions" slot-scope="{ item }">
														<!-- update po -->
														<edit class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="notSubmittedState" />
														<!-- send single po to validation -->
														<send
															class="action cursor-pointer"
															:size="18"
															@click="handleSendPOtoValidation(item.id)"
															v-if="notSubmittedState && item.validated !== 4 && item.validated !== 16"
														/>
														<!-- delete po -->
														<trash
															class="action cursor-pointer"
															:size="18"
															@click="handleDeletePO(item)"
															color="#EA4E2C"
															v-if="notSubmittedState"
														/>
														<!-- detail po -->
														<eye class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="submittedState" />
													</template>
												</card-list-builder>
											</div>
											<div v-else class="empty-data">
												<span>{{ FormMSG(18, 'No purchase orders validated') }}</span>
											</div>
										</b-overlay>
									</div>
								</div>
								<b-overlay v-else :show.sync="isBusyDataTable" rounded="lg">
									<div v-if="validatedPurchaseOrders.length === 0" class="empty-state">
										{{ FormMSG(18, 'No purchase orders validated') }}
									</div>
									<div v-else>
										<b-table
											ref="validatedPurchaseOrderTable"
											:hover="hover"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											bordered
											small
											:filter="filter"
											sticky-header="700px"
											:head-variant="hv"
											:per-page="currentTablePagePerPage"
											responsive="sm"
											:current-page="currentTablePageSubmitedPO"
											:items="validatedPurchaseOrders"
											:fields="submitedPurchaseOrdersFields"
											@row-clicked="handleRowClicked"
										>
											<template #cell(validated)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
										</b-table>
									</div>
								</b-overlay>
							</b-tab>
						</b-tabs>
					</b-card>
				</div>
			</b-row>
		</div>
		<PoService
			:value="isPoServiceModalOpen"
			:edit-data="currentPOSelected"
			@closed="handlePOModalClosed"
			@submitted="handlePOModalSubmitted"
			@refresh="handlePOModalRefresh"
			@po-service:images-change="handlePoServiceImagesChange"
			@handleActivePoModalContract="handleActivePoModalContract"
		/>
	</errorContainer>
</template>

<script>
import { mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { isNil } from '~utils';
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import PoService from '@/components/PoService/Main';
import { store } from '@/store';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { getProjectUserPoDetail, SendPurchaseOrdersSlice } from '@/cruds/budget.crud';
import globalMixin from '@/mixins/global.mixin';
import { Edit, Eye, Send, Trash2 as Trash } from 'lucide-vue';
import ValidatePOs from '../validatePO/ValidatePOs.vue';
import { getValidations } from '../../cruds/validations.crud';

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
		reject(false);
	}).then((data) => {
		value = data;
	});

	return value;
};

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};
export default {
	name: 'MyPo',
	components: {
		Loading,
		PoService,
		Edit,
		Eye,
		Send,
		Trash,
		ValidatePOs
	},
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hv: 'dark',
			Validated: 0,
			isNotSubmitedPOLoading: false,
			isSubmitedPOLoading: false,
			erreur: {},
			warning: '',
			currentPOSelected: null,
			currentTablePage: 1,
			perPage: 0,
			filter: '',
			currentTablePageSubmitedPO: 1,
			currentTablePagePerPage: 0,
			isPoServiceModalOpen: false,
			activeListTab: 0,
			unsentOrRefusedPurchaseOrders: [],
			validatedPurchaseOrders: [],
			submittedPurchaseOrders: [],
			notTransmittedAndRefusedPurchaseOrders: [],
			submittedValidatedHoDPurchaseOrders: [],
			isSubmitted: false,
			notSubmittedState: true,
			submittedState: false,
			pos: [],
			isBusyDataTable: false,
			selectMode: 'single',
			selectedColor: 'primary',
			posId: [],
			currentValidationLevel: 0,
			canSeeApprove: true
		};
	},
	computed: {
		__baseTable() {
			const _t = this.FormMSG;
			return [
				{
					key: 'id',
					label: '#' + _t(31311, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'poReference',
					label: _t(957, 'PO Reference'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: _t(31312, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: _t(31330, 'Encoded by'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'requestDate',
					label: _t(31412, 'Request date'),
					formatter: (v) => formatTableDate(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'deliveryDate',
					label: _t(31413, 'Delivery date'),
					formatter: (v) => formatTableDate(v),
					sortable: true,
					class: 'text-center'
				}
			];
		},
		unsentOrRefusedPurchaseOrderFields() {
			const _t = this.FormMSG;
			return [
				...this.__baseTable,
				{
					key: 'description',
					label: _t(31315, 'Description'),
					sortable: true
				},
				{
					key: 'supplierName',
					label: _t(31316, 'Supplier Name'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'departmentName',
					label: _t(31317, 'Department'),
					formatter: (v) => v.toLowerCase(),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amount',
					label: _t(31318, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountVat',
					label: _t(945, 'VAT'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: _t(1211, 'Total amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'sendtoapp',
					label: _t(31319, 'Send'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: _t(31320, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		unsentOrRefusedPurchaseOrderMobileFields() {
			const keys = ['sendtoapp', 'validated', 'rem'];
			return this.unsentOrRefusedPurchaseOrderFields.filter((t) => !keys.includes(t.key));
		},
		submitedPurchaseOrdersFields() {
			const _t = this.FormMSG;
			return [
				...this.__baseTable,
				{
					key: 'description',
					label: _t(31414, 'Description'),
					sortable: true
				},
				// {
				// 	key: 'comment',
				// 	label: _t(31418, 'Comment'),
				// 	formatter: v => (isEmpty(v) ? '/' : v),
				// 	sortable: true
				// },
				{
					key: 'supplierName',
					label: _t(31415, 'Supplier Name'),
					sortable: true
				},
				{
					key: 'departmentName',
					label: _t(31416, 'Department'),
					formatter: (v) => v.toLowerCase(),
					sortable: true
				},
				{
					key: 'amount',
					label: _t(31417, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				}
			];
		},
		submitedPurchaseOrdersMobileFields() {
			const keys = ['validated'];
			return this.submitedPurchaseOrdersFields.filter((t) => !keys.includes(t.key));
		},
		mobileFields() {
			if (this.notSubmittedState) {
				return this.unsentOrRefusedPurchaseOrderMobileFields;
			} else if (this.submittedState) {
				return this.submitedPurchaseOrdersMobileFields;
			}
		}
	},
	async created() {
		if (this.$route.query.validate) {
			this.currentExpenseTab = 0;
		}
		await this.getRoleValidation();
		await this.reload();
	},
	mounted() {
		/**
		 * @BusEvent
		 * @param {Object} payload
		 * @return {Promise}
		 */
		this.$bus.$on('filepreviewer:image-deleted-from-parent', (payload) => this.reload());
	},
	methods: {
		...mapActions({
			deletePurchaseOrder: 'purchaces/deletePurchaseOrder',
			sendToValidation: 'purchaces/sendToValidation',
			sendAllPurchaseOrder: 'purchaces/sendAllPurchaseOrder',
			destroySelectedPO: 'purchaces/destroySelectedPO'
		}),
		handlePoServiceImagesChange(payload) {
			const result = this.unsentOrRefusedPurchaseOrders.map((option) => {
				if (+payload.id === +option.id) {
					return {
						...payload
					};
				}
			});

			this.pos = result;
			this.unsentOrRefusedPurchaseOrders = result;
		},
		async getRoleValidation() {
			const validations = await getValidations();
			const userId = store.userID();
			const departmentId = store.state.myProfile.department;

			let haveValidationAllDepartment = null;
			let haveValidationSingleDepartment = null;

			for (let i = 0; i < validations.length; i++) {
				const element = validations[i];
				if (parseInt(element.department, 10) === -1 && parseInt(element.user.id, 10) === userId && element.type == 0) {
					haveValidationAllDepartment = element;
				} else if (parseInt(element.user.id, 10) === userId && parseInt(element.department, 10) === departmentId && element.type == 0) {
					haveValidationSingleDepartment = element;
				}
			}
			// console.log(haveValidationAllDepartment);
			if (!isNil(haveValidationSingleDepartment) && !isNil(haveValidationAllDepartment)) {
				if (haveValidationSingleDepartment.validationLevelPo > haveValidationAllDepartment.validationLevelPo) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			} else {
				if (!isNil(haveValidationSingleDepartment)) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else if (!isNil(haveValidationAllDepartment)) {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			}

			if (this.currentValidationLevel == 2 || this.currentValidationLevel == 1) {
				this.canSeeApprove = true;
			} else {
				this.canSeeApprove = false;
			}
		},
		handleActivePoModalContract(e) {
			this.isPoServiceModalOpen = e;
		},
		async openCreatePoModal() {
			this.currentPOSelected = null;
			this.isPoServiceModalOpen = true;
		},
		handSendAllPoValidation() {
			const txt = this.FormMSG(12121112, 'Please confirm that you want to send all purchase orders to validation');

			const vm = this;

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					cancelVariant: 'light',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					await SendPurchaseOrdersSlice(this.posId).then(async () => {
						this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(13, 'Pos were sent for validation.'));
						this.activeListTab = 1;
						this.notSubmittedState = false;
						this.submittedState = true;
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {Object} po - purchase order selected
		 */
		handleRowClicked(po) {
			this.currentPOSelected = po;
			this.isPoServiceModalOpen = true;
		},
		handlePOModalClosed() {
			this.currentPOSelected = null;
			this.isPoServiceModalOpen = false;
		},
		/**
		 * @param {Number} id - purchaseOrderID
		 */
		handleSendPOtoValidation(id) {
			if (isNil(id)) return;

			const txt = this.FormMSG(121211, 'Please confirm that you want to send this purchase order to validation');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'light',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					await this.sendToValidation(id).then(async () => {
						this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(14, 'Po was sent for validation.'));
						this.activeListTab = 1;
						this.notSubmittedState = false;
						this.submittedState = true;
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {Object} po
		 */
		handleDeletePO(po) {
			if (isNil(po)) return;

			const txt = this.FormMSG(121214, 'Please confirm that you want delete this purchase order');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((s) => {
					if (!s) return;

					this.deletePurchaseOrder(po.id).then(async () => {
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		async handlePOModalSubmitted(payload) {
			if (payload) {
				if (this.canSeeApprove && this.$screen.width >= 576) {
					this.activeListTab = 1;
				} else {
					this.activeListTab = 0;
				}
				this.notSubmittedState = true;
				this.submittedState = false;
				await this.reload();
				this.isPoServiceModalOpen = false;
			}
		},
		async getApprove() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			this.posId = [];
			let result = await getProjectUserPoDetail({ notSubmitted: true, personId: parseInt(store.state.myProfile.id, 10), groupBySheets: true })
				.then((records) => {
					let Pos = [];
					records.map(async (po) => {
						po.purchaseOrder.fullName = po.purchaseOrder.encoder.name + ' ' + po.purchaseOrder.encoder.firstName;
						po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
						po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
						Pos.push(po.purchaseOrder);
						this.posId.push(parseInt(po.purchaseOrder.id, 10));
					});
					this.unsentOrRefusedPurchaseOrders = Pos;
					this.pos = Pos;
				})
				.finally(() => {
					this.isBusyDataTable = false;
				});
			return result;
		},
		async getDrafts() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			this.posId = [];
			let result = await getProjectUserPoDetail({ personId: parseInt(store.state.myProfile.id, 10), groupBySheets: true })
				.then((records) => {
					let Pos = [];
					records
						.filter((item) => item.purchaseOrder.validated === 0 || item.purchaseOrder.validated === 4 || item.purchaseOrder.validated === 16)
						.map(async (po) => {
							po.purchaseOrder.fullName = po.purchaseOrder.encoder.name + ' ' + po.purchaseOrder.encoder.firstName;
							po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
							po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
							Pos.push(po.purchaseOrder);
							this.posId.push(parseInt(po.purchaseOrder.id, 10));
						});
					this.notTransmittedAndRefusedPurchaseOrders = Pos;
					this.pos = Pos;
				})
				.finally(() => {
					this.isBusyDataTable = false;
				});
			return result;
		},
		async getPending() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			this.posId = [];
			let result = await getProjectUserPoDetail({ personId: parseInt(store.state.myProfile.id, 10), groupBySheets: true })
				.then((records) => {
					let Pos = [];
					records
						.filter((item) => item.purchaseOrder.validated === 1 || item.purchaseOrder.validated === 2)
						.map(async (po) => {
							po.purchaseOrder.fullName = po.purchaseOrder.encoder.name + ' ' + po.purchaseOrder.encoder.firstName;
							po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
							po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
							Pos.push(po.purchaseOrder);
							this.posId.push(parseInt(po.purchaseOrder.id, 10));
						});
					this.submittedValidatedHoDPurchaseOrders = Pos;
					this.pos = Pos;
				})
				.finally(() => {
					this.isBusyDataTable = false;
				});
			return result;
		},
		async getApproved() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			this.posId = [];
			let result = await getProjectUserPoDetail({ personId: parseInt(store.state.myProfile.id, 10), groupBySheets: true })
				.then((records) => {
					let Pos = [];
					records
						.filter((item) => item.purchaseOrder.validated === 8)
						.map(async (po) => {
							po.purchaseOrder.fullName = po.purchaseOrder.encoder.name + ' ' + po.purchaseOrder.encoder.firstName;
							po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
							po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
							Pos.push(po.purchaseOrder);
							this.posId.push(parseInt(po.purchaseOrder.id, 10));
						});
					this.validatedPurchaseOrders = Pos;
					this.pos = Pos;
				})
				.finally(() => {
					this.isBusyDataTable = false;
				});
			return result;
		},
		async reload() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			this.posId = [];
			if (this.$screen.width >= 576 && this.canSeeApprove) {
				switch (this.activeListTab) {
					case 0:
						return this.getApprove();
					case 1:
						return this.getDrafts();
					case 2:
						return this.getPending();
					case 3:
						return this.getApproved();
					default:
						return console.log(`Sorry, tab index not found ${this.activeListTab}.`);
				}
			} else if (this.$screen.width <= 576 && this.canSeeApprove) {
				switch (this.activeListTab) {
					case 0:
						return this.getDrafts();
					case 1:
						return this.getPending();
					case 2:
						return this.getApproved();
					case 3:
						return this.getApprove();
					default:
						return console.log(`Sorry, tab index not found ${this.activeListTab}.`);
				}
			} else {
				switch (this.activeListTab) {
					case 0:
						return this.getDrafts();
					case 1:
						return this.getPending();
					case 2:
						return this.getApproved();
					default:
						return console.log(`Sorry, tab index not found ${this.activeListTab}.`);
				}
			}
		},
		async handlePOModalRefresh(payload) {
			if (payload) {
				await this.reload();
			}
		},
		handleTabChanged(status) {
			if (this.canSeeApprove) {
				switch (status) {
					case 1:
						this.notSubmittedState = true;
						this.submittedState = false;
						break;
					case 2:
						this.notSubmittedState = false;
						this.submittedState = true;
						break;
				}
			} else {
				switch (status) {
					case 0:
						this.notSubmittedState = true;
						this.submittedState = false;
						break;
					case 1:
						this.notSubmittedState = false;
						this.submittedState = true;
						break;
				}
			}
			this.activeListTab = status;
		},
		async getPoList(state) {
			let status;
			if (this.$screen.width >= 576 && this.canSeeApprove) {
				status = state + 1;
			} else {
				status = state;
			}
			this.handleTabChanged(status);
			await this.reload();
		},
		editPoMobile(item) {
			this.currentPOSelected = item;
			this.isPoServiceModalOpen = true;
		},
		viewPoMobile(item) {
			console.log({ item });
		}
	}
};
</script>

<style scoped></style>
